<template>
  <v-container fluid>
    <v-row class="mt-5 mb-2 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">MES PARTENAIRES</h2>
    </v-row>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="1"
    >
    </loading>

    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du compte</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <h3>Êtes-vous sûr de vouloir supprimer le compte : {{ partnerInterlocutor.completeName }} ?</h3>
            <p>
              En supprimant ce compte, vous consentez à la suppression des données à caractère personnelle lié à ce
              compte. Vous consentez également à ne plus pouvoir consulter :
            </p>
            <ul>
              <li>Les statistiques</li>
              <li>Les données enregistrées (Nom, prénom, ip...)</li>
              <li>Les données bancaires</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
          <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm(partnerInterlocutor.id)"
            >Confirmer la suppression</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires approuvés</v-card-title>
              <v-row v-if="itemsPartnersApproved.length > 0" align="center"
                ><v-card-text>
                  <v-icon size="48" color="EoleGreen">mdi-check-circle</v-icon>
                  <h2>{{ itemsPartnersApproved.length }}</h2>
                </v-card-text> </v-row
              ><v-row v-else align="center">
                <v-card-text>
                  <p>
                    Vous n'avez pour le moment pas de partenaires. Pour en ajouter, cliquez sur le bouton ci-dessous.
                  </p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les partenaires approuvés correspondent aux compagnies considérées comme officiel par l'outil Mes Services
            Assurance.</span
          >
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires non approuvés</v-card-title>
              <v-row v-if="itemsPartnersApprovedDisapproved.length > 0" align="center"
                ><v-card-text>
                  <v-icon size="48" color="EoleYellow">mdi-minus-circle</v-icon>
                  <h2>{{ itemsPartnersApprovedDisapproved.length }}</h2>
                </v-card-text> </v-row
              ><v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas de partenaires non approuvés pour le moment.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Les partenaires non approuvés sont les partenaires qui sont toujours en attente de décision. <br />
            Une fois la décision émise par les équipes de Mes Services Assurance votre partenaire en attente pourra être
            proposer dans la liste générale des partenaires de Mes Services Assurance.
          </span>
        </v-tooltip>
      </v-col>
      <!-- <v-col class="d-flex" cols="12" md="2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card class="flex d-flex flex-column rounded-xl" link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires repertoriés</v-card-title>

              <v-row v-if="insurers.length > 0" align="center"
                ><v-card-text>
                  <v-badge class="icon" icon="mdi-check" color="EoleGreen" bottom overlap offset-x="15" offset-y="15">
                    <i class="fa-solid fa-landmark-dome fa-3x EoleBlue--text"></i>
                  </v-badge>
                  <h2>{{ insurers.length }}</h2>
                </v-card-text> </v-row
              ><v-row v-else align="center">
                <v-card-text>
                  <p>Il n'y a pas de partenaires répertorié chez Mes Services Assurance.</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span
            >Le nombre de partenaires repertoriés chez Mes Services Assurance est le nombre de partenaires qui vous
            seront proposés directement par l'outil. <br />
            Vous pouvez cependant en ajouter s'ils ne sont pas disponibles dans la liste.
          </span>
        </v-tooltip>
      </v-col> -->
      <v-col class="d-flex" cols="12" md="3">
        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" outlined flat elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-card-title></v-card-title>
              <v-row align="center"
                ><v-card-text>
                  <v-icon size="48" color="EoleBlue">mdi-plus-circle</v-icon>
                  <p class="text-h6">Ajouter un partenaire</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>

          <v-stepper v-model="stepperAddPartner">
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-stepper-items>
              <v-stepper-content :step="1" class="no-padding">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <div v-if="alreadyExist">
                          <p>Votre partenaire est-il repertorié dans cette liste ?</p>
                          <v-select
                            label="Compagnie existante"
                            v-model="companySelected"
                            outlined
                            :items="insurers"
                            item-text="name"
                            item-value="id"
                          ></v-select>
                          <p>
                            Si la compagnie est déjà existante dans notre base vous pouvez directement l'ajouter à votre
                            liste de partenaires. Vous pourrez par la suite ajouter vos interlocuteurs à votre liste de
                            partenaires.
                          </p>
                          <v-row class="justify-center text-center">
                            <v-col align-self="end">
                              <v-btn small color="EoleBlue" class="white--text" @click="alreadyExist = !alreadyExist">
                                Mon partenaire n'est pas dans la liste
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <div>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  @input="replaceSpacesWithoutSpace"
                                  prepend-inner-icon="mdi-office-building"
                                  outlined
                                  v-model="siret"
                                  label="N° de siret"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </div>

                          <div v-if="dataPappers">
                            <v-divider class="mb-8"></v-divider>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  prepend-inner-icon="mdi-office-building"
                                  outlined
                                  v-model="company.insurerName"
                                  label="Nom de la compagnie"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  prepend-inner-icon="mdi-earth"
                                  outlined
                                  v-model="company.country"
                                  label="Pays du siège de la compagnie"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  prepend-inner-icon="mdi-office-building"
                                  outlined
                                  v-model="company.street"
                                  label="Adresse du siège"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  prepend-inner-icon="mdi-office-building"
                                  outlined
                                  v-model="company.zip"
                                  label="Code postale du siège"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  prepend-inner-icon="mdi-office-building"
                                  outlined
                                  v-model="company.city"
                                  label="Ville du siège"
                                ></v-text-field
                              ></v-col>
                            </v-row>
                          </div>
                        </div>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-text class="ma-5">
                    <v-switch label="Paramétrer mes centres de profit" inset v-model="configureCenter"></v-switch>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="!alreadyExist && !dataPappers"
                      color="EoleBlue"
                      text
                      :loading="loadingData"
                      @click="fetchPappers(siret)"
                    >
                      <v-icon left>mdi-cloud-download</v-icon> Obtenir les données</v-btn
                    >
                    <v-btn
                      v-else-if="dataPappers && !configureCenter"
                      color="EoleGreen"
                      class="white--text"
                      @click="
                        postInsurer({
                          name:
                            pappersInformations[0].denomination === '' || pappersInformations[0].denomination === null
                              ? company.insurerName
                              : pappersInformations[0].denomination,
                          city: pappersInformations[0].siege.ville,
                          zip: pappersInformations[0].siege.code_postal,
                          street: pappersInformations[0].siege.adresse_ligne_1,
                          siret: siret,
                          country: pappersInformations[0].siege.pays,
                          id_agency: $store.state.user.agency_id,
                        })
                      "
                    >
                      <v-icon left>mdi-check</v-icon>
                      Ajouter mon partenaire
                    </v-btn>
                    <v-btn
                      v-if="alreadyExist && !configureCenter"
                      @click="postPartnerExist({ agencyId: null, idInsurer: companySelected })"
                      :disabled="companySelected === ''"
                      color="EoleGreen"
                      class="white--text"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Ajouter mon partenaire
                    </v-btn>
                    <v-btn
                      v-else-if="(alreadyExist && configureCenter) || (!alreadyExist && configureCenter)"
                      @click="stepperAddPartner = 2"
                      elevation=" 0"
                      color="transparent"
                    >
                      Continuer
                      <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>

            <v-stepper-items>
              <v-stepper-content :step="2" class="no-padding">
                <v-card>
                  <v-card-title>Vos centres de profit</v-card-title>
                  <v-card-subtitle>
                    <v-row>
                      <v-col class="mt-n5" cols="12" md="4" align-self="center">
                        Combien avez-vous de centres de profit ?
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field outlined dense v-model="numberCodes" type="number"></v-text-field>
                      </v-col> </v-row
                  ></v-card-subtitle>
                  <v-card-text> </v-card-text>
                  <v-card-text class="no-padding"> </v-card-text>
                  <v-card-text>
                    <v-row v-if="codes.length > 0">
                      <v-col cols="12" md="6" v-for="center in codes" v-bind:key="center.id">
                        {{ center }}
                        <v-card outlined elevation="3" class="rounded-xl">
                          <v-card-title class="justify-center">
                            <v-text-field
                              outlined
                              dense
                              v-model="center.name"
                              label="Nom du centre de profit"
                            ></v-text-field>
                          </v-card-title>
                          <v-card-text>
                            <v-row v-for="(categorie, index) in center.categories" :key="categorie.id">
                              <v-col cols="12" md="6">
                                <v-checkbox :label="categorie.name" v-model="categorie.selected"></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="6" align-self="center" v-if="categorie.selected">
                                <v-select
                                  :items="commissionTypeCreated"
                                  item-text="name"
                                  item-value="id"
                                  v-model="categorie.typeCommission"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click=";(stepperAddPartner = 1), stepperCode++" elevation="0" color="transparent">
                      <v-icon left>mdi-chevron-left</v-icon> Retour
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                      @click="postPartnerExist({ agencyId: null, idInsurer: companySelected })"
                      :disabled="companySelected === ''"
                      color="EoleGreen"
                      class="white--text"
                    >
                      <v-icon left>mdi-check</v-icon>
                      Ajouter mon partenaire
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-dialog>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-dialog v-model="dialogExportFile" max-width="1200px">
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" outlined flat elevation="3" class="flex d-flex flex-column rounded-xl">
              <v-card-title></v-card-title>
              <v-row align="center"
                ><v-card-text>
                  <i class="fa-solid fa-file-pdf EoleBlue--text fa-3x"></i>
                  <p class="text-h6">Exporter</p>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Export</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headersPartnersExport"
                        v-model="selectedExportPartnersList"
                        :items="itemsPartnersApproved"
                        show-select
                        class="elevation-1"
                        color="EoleBlue"
                        checkbox-color="EoleBlue"
                      >
                        <template v-slot:no-data> Aucun partenaire n'a été trouvé pour l'export. </template>

                        <template v-slot:[`item.productsLength`]="{ item }"> {{ item.products.length }} </template>
                      </v-data-table>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="pdfMake"> Sauvegarder </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row class="my-5">
      <v-col cols="12" md="3">
        <v-card flat outlined>
          <v-card-text class="mb-n5">
            <v-text-field outlined label="Rechercher un partenaire" dense v-model="filterPartners"></v-text-field>
          </v-card-text>
          <v-card-text class="no-padding">
            <v-treeview
              open-all
              :active.sync="active"
              :items="filterItems(partners)"
              open-on-click
              :open.sync="open"
              activatable
              color="EoleYellow"
              transition
              selected-color="EoleError"
            >
              <template v-slot:prepend="{ item }">
                <v-badge
                  :icon="item.valid === 1 ? 'mdi-check' : 'mdi-minus-thick'"
                  :color="item.valid === 1 ? 'EoleGreen' : 'warning'"
                  class="my-2 mr-2"
                  v-if="item.id && !item.children"
                  bordered
                  bottom
                  offset-x="15"
                  offset-y="15"
                >
                  <v-icon size="36" v-if="item.url === null || item.url === ''">mdi-image-off-outline</v-icon>
                  <v-avatar v-else size="36" class="elevation-7">
                    <v-img contain :src="`https://logo.clearbit.com/${item.url}?size=128`" class=""></v-img>
                  </v-avatar>
                </v-badge>
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
            <p class="text-caption">
              {{ itemsPartnersApproved.length + itemsPartnersApprovedDisapproved.length }} partenaires trouvés
            </p>
            <br />
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="!selected" align="center" class="text-left">
            <v-col
              ><v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                >Veuillez selectionner un partenaire à afficher dans cette fenêtre.</v-alert
              ></v-col
            >
          </v-row>
          <v-card v-else :key="selected.id" class="mx-auto" flat>
            <v-card outlined class="text-center">
              <v-card-text class="profilBannerAccount" style="height: 100px">
                <v-row>
                  <v-col cols="12" md="6" class="text-left"> </v-col>
                  <v-col cols="12" md="6" class="text-right"> </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="profilSeparator">
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="profilBadge">
                <v-badge
                  :icon="selected.valid === 1 ? 'mdi-check' : 'mdi-minus-thick'"
                  bordered
                  bottom
                  :color="selected.valid === 1 ? 'EoleGreen' : 'warning'"
                  offset-x="25"
                  offset-y="20"
                >
                  <v-avatar size="96" color="white">
                    <v-icon size="48" v-if="selected.url === null || selected.url === ''">
                      mdi-image-off-outline</v-icon
                    >
                    <v-img
                      v-else
                      class="elevation-7 bordered"
                      contain
                      width="20px"
                      :src="`https://logo.clearbit.com/${selected.url}?size=128`"
                    ></v-img>
                  </v-avatar>
                </v-badge>
              </v-card-text>
              <v-card-text class="profilTabs mb-15">
                <v-row>
                  <v-col cols="12" md="9">
                    <v-tabs
                      color="EoleYellow"
                      class="text-left mt-5"
                      v-model="tab"
                      style="box-shadow: none !important"
                      background-color="transparent"
                      :show-arrows="false"
                    >
                      <v-tab href="#tab-3"> Relation </v-tab>
                      <v-tab href="#tab-4"> Produits </v-tab>
                      <v-tab href="#tab-1"> interlocuteurs </v-tab>
                    </v-tabs>
                  </v-col>

                  <v-col cols="12" md="3" class="mt-6">
                    <v-menu left right transition="slide-x-transition" close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item link target="blank" :href="`/services/partners/items?id=${selected.id}`">
                          <v-list-item-title><v-icon left>mdi-eye</v-icon>Voir la fiche partenaire</v-list-item-title>
                        </v-list-item>
                        <v-list-item color="EoleError" link @click="deletePartner(selected.id)">
                          <v-list-item-title
                            ><v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                            {{ selected.name }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="mt-16">
                <v-row class="row-name">
                  <v-col cols="12" md="6" class="text-left">
                    <h3 class="text-h5 mb-n15">
                      {{ selected.name }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text ml-1"
                            fab
                            color="EoleBlue"
                            :href="`${selected.url}`"
                            target="_blank"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-globe fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>Consulter le site internet</span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                  <v-col cols="12" md="6" class="text-right">
                    <v-chip> Siret : {{ selected.siret }} </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text>
                <v-tabs-items v-model="tab" style="background-color: transparent !important">
                  <v-tab-item :value="'tab-1'">
                    <v-card-title
                      class="no-padding mt-5"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      <v-row>
                        <v-col cols="12" md="6" class="text-left" align-self="center">MES INTERLOCUTEURS</v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-dialog v-model="dialogPutInterlocutor" max-width="800px">
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Modification interlocuteur pour {{ selected.name }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="basicRules"
                                          :error-messages="lastnameErrorMessage"
                                          v-model="editPartnerInterlocutor.lastname"
                                          label="Nom de l'interlocuteur"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="basicRules"
                                          :error-messages="firstnameErrorMessage"
                                          v-model="editPartnerInterlocutor.firstname"
                                          label="Prénom de l'interlocuteur"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="emailRules"
                                          :error-messages="emailErrorMessage"
                                          v-model="editPartnerInterlocutor.mail"
                                          prepend-inner-icon="mdi-email"
                                          label="Email"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="3">
                                        <v-text-field
                                          dense
                                          outlined
                                          v-model="editPartnerInterlocutor.cellphone"
                                          prepend-inner-icon="mdi-cellphone"
                                          label="N° (portable)"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="3">
                                        <v-text-field
                                          dense
                                          outlined
                                          v-model="editPartnerInterlocutor.phone"
                                          prepend-inner-icon="mdi-phone-classic"
                                          label="N° (fixe)"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      ><v-col cols="12">
                                        <v-text-field
                                          dense
                                          outlined
                                          :error-messages="phoneNumberErrorMessage"
                                          v-model="editPartnerInterlocutor.function"
                                          prepend-inner-icon="mdi-account-details"
                                          label="Fonction de l'interlocuteur"
                                        ></v-text-field> </v-col
                                    ></v-row>
                                  </v-form>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-btn color="warning" text @click="dialogPutInterlocutor = false">Annuler</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="EoleGreen"
                                  class="white--text"
                                  @click="putPartnerInterlocutor(editPartnerInterlocutor)"
                                  ><v-icon left>mdi-history</v-icon> Modifier l'interlocuteur</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-dialog v-model="dialogAddInterlocutor" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="EoleBlue" v-bind="attrs" v-on="on" fab icon>
                                <v-icon left>mdi-plus</v-icon>
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Nouvel interlocuteur pour {{ selected.name }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-form ref="form" v-model="validityFormPassword" lazy-validation>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="basicRules"
                                          :error-messages="lastnameErrorMessage"
                                          v-model="partnerInterlocutor.lastname"
                                          label="Nom de l'interlocuteur"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="basicRules"
                                          :error-messages="firstnameErrorMessage"
                                          v-model="partnerInterlocutor.firstname"
                                          label="Prénom de l'interlocuteur"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          dense
                                          outlined
                                          :rules="emailRules"
                                          :error-messages="emailErrorMessage"
                                          v-model="partnerInterlocutor.mail"
                                          prepend-inner-icon="mdi-email"
                                          label="Email"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="3">
                                        <v-text-field
                                          dense
                                          outlined
                                          v-model="partnerInterlocutor.cellphone"
                                          prepend-inner-icon="mdi-cellphone"
                                          label="N° (portable)"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="3">
                                        <v-text-field
                                          dense
                                          outlined
                                          v-model="partnerInterlocutor.phone"
                                          prepend-inner-icon="mdi-phone-classic"
                                          label="N° (fixe)"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      ><v-col cols="12">
                                        <v-text-field
                                          dense
                                          outlined
                                          :error-messages="phoneNumberErrorMessage"
                                          v-model="partnerInterlocutor.function"
                                          prepend-inner-icon="mdi-account-details"
                                          label="Fonction de l'interlocuteur"
                                        ></v-text-field> </v-col
                                    ></v-row>
                                  </v-form>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="warning" text @click="close">Annuler</v-btn>
                                <v-btn
                                  color="EoleGreen"
                                  class="white--text"
                                  @click="
                                    postPartnerInterlocutor({
                                      lastname: partnerInterlocutor.lastname,
                                      firstname: partnerInterlocutor.firstname,
                                      mail: partnerInterlocutor.mail,
                                      phone: partnerInterlocutor.phone,
                                      cellphone: partnerInterlocutor.cellphone,
                                      function: partnerInterlocutor.function,
                                      partnerId: selected.id,
                                    })
                                  "
                                  ><v-icon left>mdi-check</v-icon> Ajouter l'interlocuteur</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider class="mb-6 mt-2"></v-divider>
                    <loading
                      :height="80"
                      :width="80"
                      transition="fade"
                      :active.sync="waitTimeoutInterlocutor"
                      :can-cancel="false"
                      :is-full-page="false"
                      loader="spinner"
                      color="#efb639"
                      :opacity="1"
                    >
                    </loading>
                    <v-card-text v-if="interlocutors.length > 0">
                      <v-row class="text-left pa-5">
                        <v-col
                          cols="12"
                          md="3"
                          v-for="interlocutor in interlocutors"
                          :key="interlocutor.id"
                          class="d-flex"
                        >
                          <v-card
                            outlined
                            elevation="3"
                            class="justify-center text-center d-flex flex-column rounded-xl"
                          >
                            <v-row>
                              <v-col class="text-right" cols="12" md="12">
                                <v-menu left right transition="slide-x-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on" class="mr-1 mt-2 text-caption">
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item link @click="editItem(interlocutor)">
                                      <v-list-item-title
                                        ><v-icon left>mdi-pencil</v-icon>Modifier mon interlocuteur</v-list-item-title
                                      >
                                    </v-list-item>

                                    <v-list-item
                                      color="EoleError"
                                      link
                                      @click="deleteInterlocutor(interlocutor.interlocutorId, selected.id)"
                                    >
                                      <v-list-item-title
                                        ><v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                                        l'interlocuteur</v-list-item-title
                                      >
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-card-text class="flex-grow-1">
                              <v-avatar class="mb-5 interlocutors" size="64" color="EoleBlue">
                                <v-icon size="36" color="EoleBlueLighten">mdi-clipboard-account</v-icon>
                              </v-avatar>
                              <v-card-text class="align-center">
                                <p class="text-center">{{ interlocutor.lastname }} {{ interlocutor.firstname }}</p>
                                <p class="text-center">{{ interlocutor.function }}</p>
                                <v-row>
                                  <v-col cols="12" md="12" class="text-left">
                                    <strong>N° de téléphone fixe :</strong> {{ interlocutor.phone }} <br />
                                    <strong>N° de téléphone portable :</strong> {{ interlocutor.cellphone }} <br />
                                    <strong>Adresse email :</strong> {{ interlocutor.mail }} <br />
                                  </v-col>
                                  <v-col cols="12" md="6" class="text-right"> </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-text v-else class="mt-5">
                      Il n'y a pour le moment aucun interlocuteurs pour le partenaire {{ selected.name }}
                    </v-card-text>
                  </v-tab-item>

                  <v-tab-item :value="'tab-2'">
                    <v-card-title
                      class="no-padding mt-5"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      <v-row>
                        <v-col cols="12" md="6" class="text-left" align-self="center">MES CENTRES DE PROFITS</v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-dialog v-model="dialogAddCenter" max-width="800px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="primary" v-bind="attrs" v-on="on" fab icon>
                                <v-icon left>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <v-card flat>
                              <v-card-title class="EoleBlue">
                                <span class="text-h5 white--text"
                                  >Ajout d'un centre de profit pour {{ selected.name }}
                                </span>
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text class="pa-5">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field
                                      v-model="newCenter.name"
                                      label="Nom du centre de profit"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-select
                                      :items="['hebdomadaire', 'trimestrielle', 'semestrielle', 'annuelle']"
                                      v-model="newCenter.type_commission"
                                      label="Type de commission"
                                      required
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="EoleBlue" text @click="dialogAddCenter = false">Annuler</v-btn>
                                <v-btn color="EoleBlue" text @click="addCenter(selected.id)">Ajouter</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider class="mb-6 mt-2"></v-divider>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6" v-for="center in selected.centers" v-bind:key="center.id">
                          <v-card outlined elevation="3">
                            <v-card-title>{{ center.name }}</v-card-title>
                            <v-card-subtitle>{{ center.type_commission }}</v-card-subtitle>
                            <v-card-text v-for="line in center.lines" v-bind:key="line.category">
                              <v-data-table
                                :items="line.prices"
                                disable-pagination
                                hide-default-footer
                                :headers="[
                                  { text: '', value: '' },
                                  { text: line.category, value: '' },
                                ]"
                              >
                                <template v-slot:item="props">
                                  <tr>
                                    <td>
                                      {{ props.item.description }}
                                    </td>
                                    <td>
                                      <v-edit-dialog
                                        class="edit-dialog"
                                        :return-value.sync="props.item.price"
                                        @save="putCenterPrice(props.item.id, props.item.price)"
                                      >
                                        {{ props.item.price }}
                                        <template v-slot:input>
                                          <div class="edit-dialog">
                                            <v-text-field
                                              outlined
                                              class="my-5"
                                              dense
                                              v-model="props.item.price"
                                              label="Edit"
                                              single-line
                                            ></v-text-field>
                                          </div>
                                        </template>
                                      </v-edit-dialog>
                                    </td>
                                  </tr>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item :value="'tab-3'">
                    <div v-if="!waitTimeoutInterlocutor">
                      <v-card-title
                        class="no-padding mt-5"
                        style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                      >
                        <v-row>
                          <v-col cols="12" md="6" class="text-left" align-self="center">MES RELATIONS</v-col>
                          <v-col cols="12" md="6" class="text-right">
                            <v-dialog v-model="dialogPutPartner" max-width="1200px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" fab icon color="EoleBlue"
                                  ><v-icon>mdi-pencil</v-icon></v-btn
                                >
                              </template>
                              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                <v-card-text style="background-color: #f1f4f9">
                                  <v-row>
                                    <v-col cols="12" md="3" class="no-padding ml-n2">
                                      <v-card
                                        class="rounded-l-xl"
                                        color="#f1f4f9"
                                        style="background-color: #f1f4f9"
                                        flat
                                      >
                                        <v-card-title>
                                          <v-img
                                            :src="require('@/assets/images/logos/anavel_logo.png')"
                                            max-height="150px"
                                            max-width="120px"
                                            alt="logo"
                                            contain
                                          ></v-img>
                                        </v-card-title>
                                        <v-card-title class="text-center justify-center muller-capitalized"
                                          >Mes Services Assurance</v-card-title
                                        >
                                        <v-card-subtitle class="text-center justify-center"
                                          >Un logiciel ANAVEL
                                        </v-card-subtitle>
                                        <v-card-text class="no-padding">
                                          <v-img
                                            :src="require('@/assets/images/logos/layer.png')"
                                            alt="logo"
                                            width="300px"
                                            class=""
                                          >
                                          </v-img>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="12" md="9" class="no-padding d-flex">
                                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                        <v-card-title>
                                          <span class="text-h5">{{ selected.name }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                          <v-text-field
                                            label="Date de signature de la convetion de partenariat"
                                            type="date"
                                            v-model="selected.date_partnership"
                                            outlined
                                            dense
                                          ></v-text-field>
                                        </v-card-text>
                                        {{ document }}
                                        <v-card-text>
                                          <v-btn @click="importFile()"></v-btn>
                                        </v-card-text>
                                        <v-spacer></v-spacer>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn text @click="putProductLinked"> Sauvegarder </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-divider class="mb-6 mt-2"></v-divider>

                      <v-card-text class="pa-5">
                        <v-row>
                          <v-col cols="12" md="12" class="text-left">
                            Date de signature : <strong> {{ selected.date_partnership_display }} </strong></v-col
                          >

                          <v-col cols="12" md="12" class="text-left">
                            Convention de partenariat : EN ATTENTE DE DEV</v-col
                          >
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-tab-item>
                  <v-tab-item :value="'tab-4'">
                    <div v-if="!waitTimeoutInterlocutor">
                      <v-dialog v-model="dialogProduct" max-width="1200px">
                        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                          <v-card-text style="background-color: #f1f4f9">
                            <v-row>
                              <v-col cols="12" md="3" class="no-padding ml-n2">
                                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                  <v-card-title>
                                    <v-img
                                      :src="require('@/assets/images/logos/anavel_logo.png')"
                                      max-height="150px"
                                      max-width="120px"
                                      alt="logo"
                                      contain
                                    ></v-img>
                                  </v-card-title>
                                  <v-card-title class="text-center justify-center muller-capitalized"
                                    >Mes Services Assurance</v-card-title
                                  >
                                  <v-card-subtitle class="text-center justify-center"
                                    >Un logiciel ANAVEL
                                  </v-card-subtitle>
                                  <v-card-text class="no-padding">
                                    <v-img
                                      :src="require('@/assets/images/logos/layer.png')"
                                      alt="logo"
                                      width="300px"
                                      class=""
                                    >
                                    </v-img>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col cols="12" md="9" class="no-padding d-flex">
                                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                  <v-card-title>
                                    <span class="text-h5">{{ productSelected.name }}</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-textarea
                                      :label="`Justification du choix ${productSelected.name} chez ${selected.name}`"
                                      outlined
                                      dense
                                      v-model="productSelected.justification"
                                    ></v-textarea>
                                  </v-card-text>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12" md="6" class="text-left">
                                        <v-card outlined>
                                          <v-card-text class="text-center justify-center">
                                            <i class="fa-solid fa-square-minus fa-2x"></i>
                                          </v-card-text>
                                          <v-card-text>
                                            <v-textarea dense v-model="productSelected.negative"></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                      <v-col cols="12" md="6" class="text-left">
                                        <v-card outlined>
                                          <v-card-text class="text-center justify-center">
                                            <i class="fa-solid fa-square-plus fa-2x"></i>
                                          </v-card-text>
                                          <v-card-text>
                                            <v-textarea dense v-model="productSelected.positive"></v-textarea>
                                          </v-card-text>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-spacer></v-spacer>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="putProductLinked"> Sauvegarder </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                      <v-card-title
                        class="no-padding mt-5"
                        style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                      >
                        <v-row>
                          <v-col cols="12" md="6" class="text-left" align-self="center">MES PRODUITS</v-col>
                          <v-col cols="12" md="6" class="text-right"> </v-col>
                        </v-row>
                      </v-card-title>
                      <v-divider class="mb-6 mt-2"></v-divider>

                      <v-card-text class="pa-5" v-if="selected.products.length > 0">
                        <v-row class="align-center">
                          <v-col
                            cols="12"
                            md="2"
                            v-for="product in selected.products"
                            v-bind:key="product.id"
                            class="d-flex"
                          >
                            <v-card
                              class="rounded-xl align-center d-flex"
                              elevation="3"
                              outlined
                              width="200"
                              height="180"
                              @click="openProduct(product)"
                            >
                              <v-card-text class="text-center pa-5">
                                <i :class="['fa-solid', `fa-${product.icon}`, 'fa-3x EoleBlue--text']"></i>
                                <p class="mt-2">{{ product.name }}</p>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text class="pa-5" v-else>
                        Il n'y a pour le moment aucun produit en lien avec {{ selected.name }}
                      </v-card-text>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>

              <v-row class="px-5">
                <v-col cols="12" md="6" class="d-flex justify-start"> </v-col>
              </v-row>
              <v-alert
                class="mx-5 text-left text-caption"
                icon="mdi-alert"
                v-if="selected.valid === 2"
                color="EoleError"
                prominent
                text
                dense
                >Le partenaire {{ selected.name }} n'a pas encore été approuvé par les équipes de Mes Services
                Assurances. <br />
                La non-approbation du partenaire ne bloque pas l'utilisation de celui-ci pour la configuration de vos
                comptes mais n'apparaîtra pas comme choix de partenaire pour les autres utilisateurs de Mes Services
                Assurances.</v-alert
              >
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import moment from "moment"

Vue.use(Toast, { maxToasts: 2, newestOnTop: true })

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))
import VueApexCharts from "vue-apexcharts"
import { getCookie } from "@/utils/cookies"

export default {
  name: "Partners",
  data: () => ({
    document: {
      title: "",
      name: "",
      data: {
        name: "",
        type: "",
      },
    },
    fileUrl: null,
    dialogUploadFileConvention: false,
    tab: "tab-1",
    headersPartnersExport: [
      { text: "Nom du partenaire", value: "name" },
      { text: "Nombre de produits", value: "productsLength" },
    ],
    selectedExportPartnersList: [],
    dialogExportFile: false,
    dialogPutPartner: false,
    datePartnership: null,
    productSelected: {},
    dialogProduct: false,
    partners: [],
    filterPartners: "",
    dialogPutInterlocutor: false,
    configureCenter: false,
    centerCategory: [],
    newCenter: {
      name: "",
      type_commission: "",
    },
    dialogAddCenter: false,
    headerCenters: [
      { text: "", value: "description" },

      { text: "Montant", value: "price" },
    ],
    commissionTypeCreated: [
      {
        id: 1,
        name: "Hebdomadaire",
      },
      {
        id: 2,
        name: "Trimestrielle",
      },
    ],
    categorieChoice: [],
    categoriesCreated: [
      {
        id: 1,
        name: "IARD",
        typeCommission: null,
      },
      {
        id: 2,
        name: "ASSURANCE DE PERSONNE",
        typeCommission: null,
      },
    ],
    chart: null,
    stepperCode: 0,
    apexDataCode: [],
    chartOptions2: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
    },
    chartOptions: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      labels: [],
      xaxis: {},
    },
    chartSeries: [],
    numberCodes: 0,
    stepperAddPartner: 1,
    partnerInterlocutor: {
      lastname: "",
      firstname: "",
      mail: "",
      phone: "",
      cellphone: "",
      function: "",
      partnerId: "",
    },
    editPartnerInterlocutor: {
      lastname: "",
      firstname: "",
      mail: "",
      phone: "",
      cellphone: "",
      function: "",
      partnerId: "",
    },
    itemsMenu: [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }],
    company: {
      insurerName: null,
      city: null,
      zip: null,
      street: null,
      siret: null,
      country: null,
    },
    itemsPartnersApprovedDisapproved: [],
    companySelected: "",
    alreadyExist: true,
    insurers: [],
    company: [],
    pappersInformations: [],
    dataPappers: false,
    siret: null,
    loadingData: false,
    interlocutors: [],
    itemsPartnersApproved: [],
    fab: false,
    agencyName: null,
    load: false,
    /* test purpose*/
    active: [],
    activeDisapproved: [],
    openDisapproved: [],
    avatar: null,
    open: [0],
    users: [],
    /* end test purpose */
    passwordValidation: null,
    lastnameErrorMessage: "",
    firstnameErrorMessage: "",
    emailErrorMessage: "",
    phoneNumberErrorMessage: "",
    validityFormPassword: true,
    password: "",
    showPassword: false,
    passwordValidationMessage: null,
    passwordErrorMessage: "",
    basicRules: [v => !!v || "Champs requis"],
    phoneNumberRules: [
      v => !!v || "N° de téléphone requis",
      v => v.length === 10 || "N° de téléphone est composé de 10 numéro",
    ],
    passwordRules: {
      required: value => !!value || "Mot de passe requis.",
      min: v => v.length >= 8 || "Minimum 8 caractères",
    },
    emailRules: [v => !!v || "E-mail requis", v => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    waitTimeout: false,
    waitTimeoutInterlocutor: false,
    date: null,
    time: null,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    states: ["administrateur", "collaborateur"],
    dialog: false,
    dialogAddInterlocutor: false,
    dialogDelete: false,

    editedIndex: -1,
    defaultItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "",
      email: "",
      phoneNumber: "",
    },
    codes: [],
    headerCodes: [{ text: "Nom du centre de profit", value: "name" }],
    commissionerDate: null,
  }),
  computed: {
    totalValue() {
      this.codes.forEach(code => {
        code.line.forEach(line => {
          code.totalCommission += parseInt(line.value)
        })
      })
    },
    codesLineRefs() {},
    typeCommissionRefs() {
      return this.codes.map(code => ({
        ref: code.typeCommission,
        value: code.typeCommission,
      }))
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Nouveau Partenaire"
        : "Modification pour " + this.partnerInterlocutor.lastname + " " + this.partnerInterlocutor.firstname
    },
    // formTitleInterlocutor() {
    //   return this.editedIndex === -1 ? "Ajouter un nouvel interlocuteur" : "Modification"
    // },
    items() {
      return [
        {
          name: `Mes partenaires approuvé (${this.itemsPartnersApproved.length})`,
          children: this.itemsPartnersApproved,
        },
        {
          name: `Mes partenaires non approuvé (${this.itemsPartnersApprovedDisapproved.length})`,
          children: this.itemsPartnersApprovedDisapproved,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      this.fetchInterlocutorsByPartnerId(id)

      return (
        this.itemsPartnersApproved.find(partner => partner.id === id) ||
        this.itemsPartnersApprovedDisapproved.find(partner => partner.id === id)
      )
    },
  },

  watch: {
    // commissionRefs: {
    //   handler(newRefs, oldRefs) {
    //     console.log(newRefs, oldRefs)
    //   }
    // },
    // typeCommissionRefs: {
    //   handler(newRefs, oldRefs) {
    //     console.log(newRefs, oldRefs)
    //     newRefs.forEach((newRef, index) => {
    //       if (newRef.value !== oldRefs[index].value) {
    //         this.codes[index].line = []
    //         switch (newRef.value) {
    //           case 'hebdomadaire':
    //             for (let i = 0; i < 52; i++) {
    //               this.codes[index].line.push({
    //                 id: i,
    //                 name: 'Semaine ' + (i + 1),
    //                 value: 0,
    //               })

    //             }
    //             break;
    //           case 'trimestrielle':
    //             for (let i = 0; i < 4; i++) {
    //               this.codes[index].line.push({
    //                 id: i,
    //                 name: 'Trimestre ' + (i + 1),
    //                 value: 0,
    //               })
    //             }
    //             break;
    //           case 'semestrielle':
    //             for (let i = 0; i < 2; i++) {
    //               this.codes[index].line.push({
    //                 id: i,
    //                 name: 'Semestre ' + (i + 1),
    //                 value: 0,
    //               })
    //             }
    //             break;
    //           case 'annuelle':
    //             for (let i = 0; i < 1; i++) {
    //               this.codes[index].line.push({
    //                 id: i,
    //                 name: 'Année',
    //                 value: 0,
    //               })
    //             }
    //             break;
    //         }
    //         this.codes[index].typeCommission = newRef.value
    //         this.codes[index].totalCommission = 0
    //       }
    //     });
    //   },
    //   deep: true, // Surveillez les changements profonds dans chaque référence
    // },
    numberCodes(val) {
      // let array = []
      // for (let i = 0; i < val; i++) {
      //   array.push({
      //     name: "",
      //     typeCommission: "",
      //   })
      // }
      // this.codes = array

      /// add new value in this.codes array when numberCodes change if numberCodes > this.codes.length else remove value in this.codes array
      if (val > this.codes.length) {
        for (let i = this.codes.length; i < val; i++) {
          this.codes.push({
            id: i,
            name: "A changer",
            categories: this.categoriesCreated.map(categorie => ({
              ...categorie,
              selected: false,
            })),
          })
        }
      } else {
        for (let i = this.codes.length; i > val; i--) {
          this.codes.pop()
        }
      }
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },

    findSymbolForClass(selector) {
      var result = ""
      var sheets = document.styleSheets

      for (var sheetNr = 0; sheetNr < sheets.length; sheetNr++) {
        var content = findCSSRuleContent(sheets[sheetNr], selector)

        if (content) {
          result = stripQuotes(content)
          break
        }
      }
      return result
    },

    findCSSRuleContent(mySheet, selector) {
      var ruleContent = ""
      var rules = mySheet.cssRules ? mySheet.cssRules : mySheet.rules

      for (var i = 0; i < rules.length; i++) {
        var text = rules[i].selectorText
        if (text && text.indexOf(selector) >= 0) {
          ruleContent = rules[i].style.content
          break
        }
      }

      return ruleContent
    },

    stripQuotes(string) {
      var len = string.length
      return string.slice(1, len - 1)
    },
  },

  async created() {
    this.fetchInsurers()
    this.initialize()
    this.fetchPartners()
      .then(() => {})
      .finally(() => {
        this.open = [1]
      })

    //this.fetchUsers()
    this.agencyName = localStorage.getItem("agency")
  },
  components: {
    appbar,
    Loading,
    apexchart: VueApexCharts,
  },

  methods: {
    importFile() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.addEventListener("change", event => {
        this.document.data = event.target.files[0]
        this.document.name = event.target.files[0].name
        this.document.type = event.target.files[0].type
        this.fileUrl = URL.createObjectURL(this.document.data)
        this.dialogUploadFileConvention = true

        // this.uploadFile(event, type, area, subType)
      })
      input.click()
    },
    pdfMake() {
      const docDefinition = {
        content: [],
        styles: {
          header: {
            bold: true,
            fontSize: 12,
            color: "#FCC03C", // Couleur du texte pour les en-têtes
            fillColor: "#001F47", // Couleur de fond pour les en-têtes
          },
          cell: {
            fontSize: 10,
            padding: 5,
          },
        },
      }

      this.selectedExportPartnersList.forEach(company => {
        const companyTable = {
          table: {
            widths: ["*", "*", "*", "*"],
            body: [
              [{ text: `${company.name}`, colSpan: 4, style: "header" }, {}, {}, {}],
              [
                { text: "Produit", style: "header" },
                { text: "Justification", style: "header" },
                { text: "Points positifs", style: "header" },
                { text: "Points négatifs", style: "header" },
              ],
            ],
          },
        }

        company.products.forEach(product => {
          companyTable.table.body.push([
            { text: `${product.name}`, style: "cell" },
            { text: `${product.justification}`, style: "cell" },
            { text: `${product.positive}`, style: "cell" },
            { text: `${product.negative}`, style: "cell" },
          ])
        })

        console.log(companyTable)

        docDefinition.content.push(companyTable)

        docDefinition.content.push({
          text: " ",
          pageBreak: "after",
        })
      })

      pdfMake.createPdf(docDefinition).download("compagnies_produits_tableau.pdf")
    },

    async postDatePartnership() {
      const formData = new FormData()
      formData.append("place", "postJustificationProduct")
      formData.append("id", this.selected.id)
      formData.append("date", this.datePartnership)

      try {
        const response = await fetch(`${config.apiUri}/php/pages/partners.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        }).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien ajouté votre centre.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }

          this.datePartnership = null
        })
        this.dialogProduct = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putProductLinked() {
      const formData = new FormData()
      formData.append("place", "postJustificationProduct")
      formData.append("id", this.productSelected.id_product_linked)
      formData.append("justification", this.productSelected.justification)
      formData.append("positive", this.productSelected.positive)
      formData.append("negative", this.productSelected.negative)

      try {
        const response = await fetch(`${config.apiUri}/php/pages/partners.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        }).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien ajouté votre centre.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }
        })
        this.dialogProduct = false
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    openProduct(product) {
      this.productSelected = product
      this.dialogProduct = true
    },
    filterItems(item) {
      let search = this.filterPartners.toLowerCase()
      return [
        {
          id: 1,
          name: `Mes partenaires approuvé (${this.itemsPartnersApproved.length})`,
          children: this.itemsPartnersApproved.filter(user => user.name.toLowerCase().includes(search)),
          open: false,
        },
        {
          id: 2,
          name: `Mes partenaires non approuvé (${this.itemsPartnersApprovedDisapproved.length})`,
          children: this.itemsPartnersApprovedDisapproved.filter(user => user.name.toLowerCase().includes(search)),
          open: false,
        },
      ]
    },
    async putPartnerInterlocutor(data) {
      try {
        const response = await fetch(
          `${config.apiUri}/php/pages/interlocutors.php?idInterlocutor=${data.interlocutorId}&lastname=${data.lastname}&firstname=${data.firstname}&mail=${data.mail}&cellphone=${data.cellphone}&phone=${data.phone}&function=${data.function}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien modifier l'interlocuteur.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible de modifier l'interlocuteur`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }
        })
        // this.initialize()
        // this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier l'interlocuteur`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putCenterPrice(idPartnerCenterPrice, price) {
      try {
        const response = await fetch(
          `${config.apiUri}/php/pages/partners.php?place=updateCenterPrice&idPartnerCenterPrice=${idPartnerCenterPrice}&price=${price}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien modifier le prix de votre centre.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }
        })
        // this.initialize()
        // this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchCenterCategory(idAgency) {
      try {
        await fetch(`${config.apiUri}/agencies/${idAgency}/partners/center_categories`, {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
          .then(resp => resp.json())
          .then(data => {
            this.centerCategory = data
            console.log(this.centerCategory)
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de récupérer les catégories de centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async addCenterOnCreate(idPartner, idCategory, name, typeCommission) {
      const formData = new FormData()
      formData.append("place", "addCenter")
      formData.append("idPartner", idPartner)
      formData.append("name", this.newCenter.name)
      formData.append("typeCommission", this.newCenter.type_commission)
      formData.append("idCategory", 1)

      try {
        const response = await fetch(`${config.apiUri}/php/pages/partners.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        }).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien ajouté votre centre.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }
        })
        this.initialize()
        this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async addCenter(idPartner) {
      const formData = new FormData()
      formData.append("place", "addCenter")
      formData.append("idPartner", idPartner)
      formData.append("name", this.newCenter.name)
      formData.append("typeCommission", this.newCenter.type_commission)
      formData.append("idCategory", 1)

      try {
        const response = await fetch(`${config.apiUri}/php/pages/partners.php`, {
          mode: "cors",
          method: "POST",
          body: formData,
        }).then(resp => {
          if (resp.status === 200) {
            this.$toast.info(`Vous avez bien ajouté votre centre.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          } else {
            this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
              position: "bottom-right",
              timeout: 3000,
            })
          }
        })
        this.initialize()
        this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un centre de profit'`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    updateSeriesData() {
      /// Update apexChart data
      this.apexCategoryCode = []
      this.codes.forEach((code, index) => {
        this.apexDataCode = []
        code.line.forEach(line => {
          this.apexDataCode.push(line.value)
        })
        console.log(index, code.name, this.apexDataCode)

        // update data in chartSeries for eahc code
        this.chartSeries[index] = {
          name: code.name,
          data: this.apexDataCode,
        }
        this.$refs.myChart.updateSeries(this.chartSeries)
      })
    },
    calculateAverage(lines) {
      let median = 0
      for (const line of lines) {
        median += parseInt(line.value)
      }
      return median / lines.length
    },
    calculateTotal(lines) {
      let sum = 0
      for (const line of lines) {
        sum += parseInt(line.value)
      }
      return sum
    },
    itemsCodes() {
      for (let i = 0; i < this.numberCodes; i++) {
        this.codes.push({
          name: "",
          typeCommission: "",
        })
      }

      this.codes.forEach(code => {
        this.chartSeries.push({
          name: code.name,
          data: [],
        })
      })
    },
    replaceSpacesWithoutSpace() {
      this.siret = this.siret.replace(/ /g, "")
    },
    async deletePartner(idPartner) {
      let idAgency = this.$store.state.user.agency_id

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/partners.php?idAgency=${idAgency}&idPartner=${idPartner}`,
          {
            mode: "cors",
            method: "DELETE",
            headers: headers,
          },
        )
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Votre partenaie à bien été supprimée.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
        this.initialize()
        this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le partenaire`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async deleteInterlocutor(interlocutorId, partnerId) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/php/pages/interlocutors.php?interlocutorId=${interlocutorId}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json())
          .then(() => {
            this.$toast.info(`L'interlocuteur à bien été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
        this.initialize()
        this.interlocutors = []
        this.fetchPartners()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer l'interlocuteur`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async postInsurer(data) {
      try {
        await fetch(`${config.apiUri}/insurers`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(data),
        })

        this.$toast.success(`Vous avez ajouté ${data.name} à votre liste de partenaire`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.initialize()

        this.fetchPartners()
        this.close()

        this.alreadyExist = false
        this.siret = ""
        this.dataPappers = false
        this.company = {
          insurerName: "",
          city: "",
          zip: "",
          street: "",
          siret: "",
          country: "",
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async postPartnerInterlocutor(data) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/interlocutors.php?lastname=${data.lastname}&firstname=${data.firstname}&mail=${data.mail}&cellphone=${data.cellphone}&phone=${data.phone}&function=${data.function}&partnerId=${data.partnerId}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )

        this.fetchInterlocutorsByPartnerId(data.partnerId)
        this.dialogAddInterlocutor = false

        this.partnerInterlocutor = {
          lastname: "",
          firstname: "",
          mail: "",
          phone: "",
          cellphone: "",
          function: "",
          partnerId: "",
        }
        this.$toast.success(`Vous avez ajouté à votre liste de partenaire`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async postPartnerExist(data) {
      const idAgency = this.$store.state.user.agency_id

      try {
        // Envoie de la requête pour ajouter un partenaire
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/partners`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            idInsurer: data.idInsurer,
          }),
        })

        if (response.ok) {
          this.$toast.info("Le partenaire a été ajouté avec succès.", {
            position: "bottom-right",
            timeout: 3000,
          })
          this.initialize()
          this.fetchPartners()
          this.close()
        } else {
          const errorMessage = await response.text()
          this.$toast.error(`Erreur lors de l'ajout du partenaire : ${errorMessage}`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (error) {
        console.error("Erreur réseau ou interne :", error)
        this.$toast.error("Une erreur interne est survenue. Veuillez réessayer plus tard.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchInsurers() {
      this.loading = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/insurers/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.forEach(element => {
          this.insurers.push({
            id: element.id,
            name: element.name,
          })
        })
        this.insurers.sort(el => {
          return el.name
        })
        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    // ? Method to get informations using a PAPPERS API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      this.pappersInformations = []
      this.loadingData = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          this.siretNotFound = false
          this.siretNotFoundMessage = ""
          let data = await response.json()

          this.pappersInformations.push(data)

          this.dataPappers = true

          this.company = {
            insurerName: this.pappersInformations[0].denomination,
            city: this.pappersInformations[0].siege.ville,
            zip: this.pappersInformations[0].siege.code_postal,
            street: this.pappersInformations[0].siege.adresse_ligne_1,
            siret: siret,
            country: this.pappersInformations[0].siege.pays,
          }
        }
        this.loadingData = false
      } catch (e) {
        console.log(e)
      }
    },

    loaderData() {
      this.loadingData = true

      setTimeout(() => {
        this.loadingData = false
      }, 3000)
    },
    async fetchInterlocutorsByPartnerId(partnerId) {
      let agencyId = this.$store.state.user.agency_id

      this.interlocutors = []

      this.waitTimeoutInterlocutor = true

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/${partnerId}/interlocutors`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (response.ok) {
          data.forEach(el => {
            this.interlocutors.push(el)
          })
        } else {
        }

        this.waitTimeoutInterlocutor = false
      } catch (e) {
        console.log(e)
      }
    },

    validateFormPassword(id) {
      if (this.password.length >= 8 && this.password === this.passwordValidation) {
        if (this.$refs.form.validate()) {
          this.save(id)
        }
      } else {
        this.passwordErrorMessage = "Les mots de passes doivent correspondre"
      }
    },
    getCurrentDate() {
      var today = new Date()
      var date = today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
      var time = today.getHours() + ":" + today.getMinutes()
      this.date = date
      this.time = time
    },
    async fetchPartners() {
      this.loading = true
      this.waitTimeout = true
      let agencyId = this.$store.state.user.agency_id

      await pause(1500)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/approved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          let series = []

          element.centers.forEach(center => {
            console.log(center)
            center.lines.forEach(line => {
              let data = []

              line.prices.forEach(el => {
                data.push(el.price)
                this.chartOptions2.xaxis.categories.push(el.description)
              })
              series.push({
                name: `${center.name} - ${line.category}`,
                data: data,
              })

              // // data.push(line.price)
              // line.forEach(el => {
              //   el.prices.forEach(e => {
              //     data.push(e.price)
              //   })
              //   series.push({
              //     name: `${center.name} - ${line.category}`,
              //     data: data
              //   })
              // })
            })
          })

          this.partners.push({
            id: parseInt(element.partnerId),
            name: element.name,
            url: element.website_url,
            city: element.city,
            street: element.street,
            zip: element.zip,
            idInsurer: element.insurerId,
            siret: element.siret,
            valid: parseInt(element.valid),
            centers: element.centers,
            chartSeries: series,
          })

          this.itemsPartnersApproved.push({
            id: element.partnerId,
            name: element.name,
            url: element.website_url,
            city: element.city,
            street: element.street,
            zip: element.zip,
            idInsurer: element.insurerId,
            siret: element.siret,
            valid: parseInt(element.valid),
            centers: element.centers,
            chartSeries: series,
            products: element.products,
            date_partnership: element.date_partnership,
            date_partnership_display: moment(element.date_partnership).format("DD/MM/YYYY"),
          })
        })

        this.loading = false
        this.fetch = true
        this.waitTimeout = false
        this.fetchPartnersDisapproved()

        this.fetchCenterCategory(agencyId)

        // this.$toast.success(
        //   `Les données ont correctement été récupérées.\nNous avons trouvé ${data.length} comptes utilisateurs lié à votre profil`,
        //   {
        //     position: "bottom-right",
        //     timeout: 3000,
        //   },
        // )
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchPartnersDisapproved() {
      this.loading = true
      this.waitTimeout = true

      await pause(1500)

      try {
        let headers = new Headers()
        let agencyId = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${agencyId}/partners/disapproved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        data.forEach(element => {
          this.itemsPartnersApprovedDisapproved.push({
            id: element.partnerId,
            name: element.name,
            url: element.website_url,
            city: element.city,
            street: element.street,
            zip: element.zip,
            idInsurer: element.insurerId,
            siret: element.siret,
            valid: element.valid,
            products: element.products,
            date_partnership: element.date_partnership,
            date_partnership_display: moment(element.date_partnership).format("DD/MM/YYYY"),
          })

          this.partners.push({
            id: parseInt(element.partnerId),
            name: element.name,
            url: element.website_url,
            city: element.city,
            street: element.street,
            zip: element.zip,
            idInsurer: element.insurerId,
            siret: element.siret,
            valid: element.valid,
          })
        })

        this.loading = false
        this.fetch = true
        this.waitTimeout = false

        // this.$toast.success(
        //   `Les données ont correctement été récupérées.\nNous avons trouvé ${data.length} comptes utilisateurs lié à votre profil`,
        //   {
        //     position: "bottom-right",
        //     timeout: 3000,
        //   },
        // )
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    getLength() {
      return this.users.length
    },
    initialize() {
      this.itemsPartnersApproved = []
      this.itemsPartnersApprovedDisapproved = []
    },

    editItem(item) {
      this.editPartnerInterlocutor = Object.assign({}, item)
      this.dialogPutInterlocutor = true
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.partnerInterlocutor = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm(id) {
      this.users.splice(this.editedIndex, 1)

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        fetch(`${config.apiUri}/accounts/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le compte a correctement été supprimé.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }

      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.alreadyExist = true
      this.$nextTick(() => {
        this.partnerInterlocutor = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.partnerInterlocutor = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async put(idUser, data) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let state

        if (data.grade === "collaborateur") {
          state = 2
        } else if (data.grade === "administrateur") {
          state = 1
        }

        const response = await fetch(
          `${config.apiUri}/php/pages/accounts.php?id=${idUser}&lastname=${data.lastname}&firstname=${data.firstname}&state=${state}&email=${data.email}&phoneNumber=${data.phoneNumber}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
            body: JSON.stringify(data),
          },
        )
        this.$toast.info(
          `Vous venez de modifier le compte ${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} avec succès.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.initialize()

        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    a(msg) {
      alert(msg)
    },

    async post(data) {
      try {
        let agencyId = this.$store.state.user.agency_id
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/accounts.php?lastname=${data.lastname}&firstname=${data.firstname}&idState=2&email=${data.email}&phoneNumber=${data.phoneNumber}&idMasterAccount=${data.idMasterAccount}&password=${data.password}&agencyId=${agencyId}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          },
        )

        this.sendmail(data)

        this.$toast.success(
          `Vous avez ajouté un nouveau compte.\n${data.lastname.toUpperCase()} ${data.firstname.toUpperCase()} peut désormais se connecter.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )

        this.initialize()
        this.fetchUsers()
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter un nouveau compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    save(idUser) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.partnerInterlocutor)
        this.put(idUser, {
          lastname: this.partnerInterlocutor.lastname,
          firstname: this.partnerInterlocutor.firstname,
          grade: this.partnerInterlocutor.statut,
          email: this.partnerInterlocutor.email,
          phoneNumber: this.partnerInterlocutor.phoneNumber,
        })
      } else {
        this.users.push(this.partnerInterlocutor)
        this.post({
          lastname: this.partnerInterlocutor.lastname,
          firstname: this.partnerInterlocutor.firstname,
          email: this.partnerInterlocutor.email,
          phoneNumber: this.partnerInterlocutor.phoneNumber,
          idMasterAccount:
            localStorage.getItem("linked") != null
              ? localStorage.getItem("master_account_linked")
              : localStorage.getItem("user_id"),
          password: this.password,
        })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss">
.interlocutors i:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.3s;
}
.no-padding {
  padding: 0 !important;
}

.not-allowed:hover {
  cursor: not-allowed;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

.profilTabs {
  position: absolute;
  top: 45px;
  left: 115px;
}
// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
  }
}

@media screen and (min-width: 1500px) {
  .profilBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
  }
}

.profilBannerAccount {
  // background-image: url("../../../../src/assets/images/profils/agence.png");
  height: 100%;
  width: 100%;
}
.profilSeparator {
  position: absolute;
  padding: 0;
  top: 113px;
}
</style>
